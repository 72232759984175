import { useQuery } from '@vue/apollo-composable'
import * as currencyApi from '@/graphql/currency'
import { ref } from 'vue'

export type ParamsInpt = {
  current: number
  size: number
  exchangeOri: string | null
  applicationStatusApp: string | null
  tradeAccount: string | null
  deviceType: number
  operatorAccount: string | null
  clientName: string | null
}
type RecordType = {
  clientName: string
  exchangeOutMoney: string
  outCurrencyType: string
  exchangeInMoney: string
  inCurrencyType: string
  applicationStatus: string
  exchangeOri: string
  operatorAccount: string
  managerAccount: string
}
export default function useCurrencyRecord<T> (params: T) {
  const tableData = ref([])
  const total = ref(0)
  const orientationList = ref()
  const statusList = ref()
  const nameList = ref()
  const accountList = ref()
  const operList = ref()
  const { refetch, loading, onResult } = useQuery(
    currencyApi.getCurrencyRecord,
    {
      filterRecord: params,
      filterDict: {
        markList: [
          'FOREIGN_EXCHANGE_ORI',
          'AO_FOREGN_EXCHANGE_APP_STATUS',
          'SEC_MONEY_TYPE'
        ]
      }
    }
  )
  onResult(res => {
    const {
      foreignExchangeRecords,
      findDictSystem,
      lpoaManagersBindInfoList,
      getOperators,
      queryCustomerName
    } = res?.data
    if (findDictSystem.code === 0) {
      orientationList.value = [
        { name: '全部', value: '' },
        ...findDictSystem.result.FOREIGN_EXCHANGE_ORI
      ]
      statusList.value = [
        { name: '全部', value: null },
        ...findDictSystem.result.AO_FOREGN_EXCHANGE_APP_STATUS
      ]
      // 客户名字
      const name = queryCustomerName?.result?.clientNameList.map(
        (item: string) => {
          return {
            name: item,
            value: item
          }
        }
      )
      nameList.value = [{ name: '全部', value: '' }, ...name]
      // 交易账号
      const account = lpoaManagersBindInfoList.data[0].bindInfo.map(
        (item: { account: string }) => {
          return {
            name: item.account,
            value: item.account
          }
        }
      )
      accountList.value = [{ name: '全部', value: null }, ...account]
      //   操作人
      const operators = getOperators.data[0].operatorList.map(
        (item: { account: string }) => {
          return {
            name: item.account,
            value: item.account
          }
        }
      )
      operList.value = [{ name: '全部', value: '' }, ...operators]
      const currencyType = findDictSystem.result.SEC_MONEY_TYPE
      const setType = (list: any, val: string) =>
        list.filter((item: { value: string }) => item.value === val)[0]?.name
      // 表格
      if (foreignExchangeRecords.code === 0) {
        tableData.value = foreignExchangeRecords?.result?.records?.map(
          (item: RecordType) => {
            return {
              cName: item.clientName || '--',
              outMoney:
                Number(item.exchangeOutMoney).toLocaleString() +
                setType(currencyType, item.outCurrencyType),
              inMoney:
                Number(item.exchangeInMoney).toLocaleString() +
                setType(currencyType, item.inCurrencyType),
              status: setType(statusList.value, item.applicationStatus),
              ori: setType(orientationList.value, item.exchangeOri),
              ...item
            }
          }
        )
        console.log('tableData', tableData)
        total.value = foreignExchangeRecords?.result?.total
      }
    }
    console.log('res', res)
  })
  return {
    loading,
    tableData,
    total,
    statusList,
    orientationList,
    nameList,
    accountList,
    operList,
    refetchRecord: refetch
  }
}
