
import { defineComponent, ref } from 'vue'
import useCurrencyData, { ChangeType } from './hooks/useCurrencyData'
import usePasswordDialog from './hooks/usePasswordDialog'
import useGetForeign from './hooks/useGetForeign'

export default defineComponent({
  setup () {
    const tipsDialog = ref(false)
    const outMoney = ref()
    const {
      leftLoading,
      cashInfo,
      cashInClick,
      cashOutClick,
      exchangeTypeClick,
      cashInItem,
      cashOutItem,
      accountList,
      accountActive,
      cashOutList,
      cashInList,
      foreignList,
      refetch,
      btnDisabled,
      cashOutInputValueChange,
      cashInInputValueChange,
      clickAllOutValue,
      cashBlur
    } = useCurrencyData()

    const { passwordInfo, applyClick, checkPwd, pwdLoading, submitLoading } = usePasswordDialog(cashInfo, foreignList, cashInItem, cashOutItem, accountActive, btnDisabled)

    const { rightLoading, date, tableData, disabledDate, dateChange } = useGetForeign()

    // 切换交易账号
    const refGetData = (val: string) => {
      const params = {
        filter: {
          fundAccount: val
        }
      }
      refetch(params)
    }

    return {
      tipsDialog,
      leftLoading,
      rightLoading,
      cashInfo,
      foreignList,
      outMoney,
      accountActive,
      accountList,
      cashOutList,
      cashInList,
      cashInClick,
      cashOutClick,
      exchangeTypeClick,
      cashInItem,
      cashOutItem,
      refetch,
      btnDisabled,
      refGetData,
      applyClick,
      passwordInfo,
      checkPwd,
      cashOutInputValueChange,
      cashInInputValueChange,
      clickAllOutValue,
      date,
      tableData,
      disabledDate,
      dateChange,
      pwdLoading,
      submitLoading,
      cashBlur,
      ChangeType
    }
  }
})
