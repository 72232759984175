import { useQuery } from '@vue/apollo-composable'
import { ref } from 'vue'
import * as currancyApi from '@/graphql/currency'
import dayjs from 'dayjs'
import Decimal from 'decimal.js'

enum Revenue {
  UP = 'R',
  DOWN = 'F',
  FLOT = 'B'
}

export default function useGetForeign () {
  const date = ref(new Date())

  const tableData = ref([])

  const params = {
    filter: {}
  }
  const {
    loading: rightLoading,
    onResult,
    refetch
  } = useQuery(currancyApi.foreignExchangeRatesByDate, params)

  const dateChange = (val: Date) => {
    const params = {
      filter: {
        beginExchangeDate: Number(dayjs(val).format('YYYYMMDD')),
        endExchangeDate: Number(dayjs(val).format('YYYYMMDD'))
      }
    }
    refetch(params)
  }

  onResult(res => {
    const { foreignExchangeRatesByDate } = res.data
    if (foreignExchangeRatesByDate.code === 0) {
      if (foreignExchangeRatesByDate.result.length) {
        let color
        let value
        date.value = dayjs(
          foreignExchangeRatesByDate.result[0].exchangeDate.toString()
        ).toDate()
        tableData.value =
          foreignExchangeRatesByDate.result[0]?.foreignExchangeRateInfoList.map(
            (val: { changePctMark: string, changePct: string }) => {
              if (val.changePct === '--') {
                value = '--'
                color = ''
              } else {
                switch (val.changePctMark) {
                  case Revenue.UP:
                    color = 'up'
                    value = `+${new Decimal(val.changePct).mul(100).toString()}%`
                    break
                  case Revenue.DOWN:
                    color = 'down'
                    value = `-${new Decimal(val.changePct).mul(100).toString()}%`
                    break
                  default:
                    color = ''
                    value = `${new Decimal(val.changePct).mul(100).toString()}%`
                    break
                }
              }
              return {
                ...val,
                color,
                changePct: value
              }
            }
          )
      } else {
        tableData.value = []
      }
    }
  })

  const disabledDate = (time: Date) => {
    return time.getTime() > Date.now()
  }

  // watch(() => store.getters.currencyMessage, (val: string) => {
  //   const { data } = JSON.parse(val)
  //   tableData.value.map((val: { outCurrencyType: string, inCurrencyType: string }) => {
  //     const d = data?.message.filter((v: { outCurrencyType: string, inCurrencyType: string }) => (val.outCurrencyType === v.outCurrencyType && val.inCurrencyType === v.inCurrencyType))
  //   })
  //   console.log('val', data)
  // }, { deep: true })
  return {
    rightLoading,
    date,
    disabledDate,
    tableData,
    dateChange
  }
}
