import gql from 'graphql-tag'

export const getCurrentyData = gql`
  query getCurrency($filter: MDFilterFindDictSystemInput!) {
    findDictSystem: findDictSystem(filter: $filter) {
      code
      message
      result
    }
    foreignExchangeSetup: foreignExchangeSetup {
      code
      message
      result {
        isPartSwap
        isHkdStartAmount
        isUsdStartAmount
        hkdInLargeAmount
        hkdOutLargeAmount
        usdInLargeAmount
        usdOutLargeAmount
        isCnhStartAmount
        cnhStartAmount
        cnhInCustomerLimit
        cnhOutCustomerLimit
        cnhInLargeAmount
        cnhOutLargeAmount
        isEurStartAmount
        eurStartAmount
        eurInCustomerLimit
        eurOutCustomerLimit
        eurInLargeAmount
        eurOutLargeAmount
        isGbpStartAmount
        gbpStartAmount
        gbpInCustomerLimit
        gbpOutCustomerLimit
        gbpInLargeAmount
        gbpOutLargeAmount
        businessTimeType
        residualLimitList {
          currencyType
          residualLimit
        }
        foreignExchangeRateList {
          outCurrencyType
          inCurrencyType
          ratePrice
          algorithmType
        }
        hkdStartAmount
        usdStartAmount
        businessTimePeriod {
          beginTime
          endTime
        }
      }
    } 
    lpoaManagersBindInfoList: lpoaManagersBindInfoList {
      data {
        manager
        bindInfo {
          account
          source
          remark
          serialNo
          nameCn
          englishFirstname
          englishLastname
          userId
          routingGroup
        }
      }
    }
  }
`
export const getCurrencyRecord = gql`
  query getCurrencyRecord(
    $filterDict: MDFilterFindDictSystemInput!
    $filterRecord: MDFilterForeignExchangeRecordsInput!
  ) {
    findDictSystem: findDictSystem(filter: $filterDict) {
      code
      message
      result
    }
    foreignExchangeRecords: foreignExchangeRecords(filter: $filterRecord) {
      code
      message
      result {
        total
        size
        current
        pages
        records {
          exchangeOri
          outCurrencyType
          inCurrencyType
          exchangeOutMoney
          exchangeInMoney
          applicationStatus
          createTime
          reasonDesc
          clientName
          deviceType
          operatorAccount
          managerAccount
          tradeAccount
        }
        optimizeCountSql
        hitCount
        countId
        maxLimit
        searchCount
      }
    }
    lpoaManagersBindInfoList: lpoaManagersBindInfoList {
      data {
        manager
        bindInfo {
          account
          source
          remark
          serialNo
          nameCn
          englishFirstname
          englishLastname
          userId
          routingGroup
        }
      }
    }
    queryCustomerName: queryCustomerName {
      code
      message
      result {
        clientNameList
      }
    }
    getOperators: getOperators {
      ok
      data {
        managerId
        operatorList {
          id
          account
          areaCode
          phone
          name
          nameEn
          email
        }
      }
      reason {
        desc
        err
      }
    }
  }
`

export const getAccBalance = gql`
query fundAccBalanceList($filter: FundAccBalanceListInputType!) {
  fundAccBalanceList(filter: $filter) {
    ok
    data {
      fundAccount
      assetProp
      moneyType
      currentBalance
      enableBalance
      fetchBalance
      frozenBalance
      marginCall
      unsettBalance
    }
    reason {
      desc
      err
    }
  } 
}
`

export const checkSessionPassword = gql`
  mutation checkSessionPassword($param: CheckSessionPasswordInput!) {
    checkSessionPassword(param: $param) {
      ok
      data {
        token
      }
      reason {
        desc
        err
      }
    } 
  }
`

export const foreignExchangeApplicationCommit = gql`
  mutation foreignExchangeApplicationCommit($param: MDForeignExchangeApplicationCommitInput!) {
  foreignExchangeApplicationCommit(param: $param) {
    code
    message
    result
  }  
}
`
// 查询外汇汇率
export const foreignExchangeRatesByDate = gql`
  query foreignExchangeRatesByDate($filter: MDFilterForeignExchangeRateByDateInput!) {
  foreignExchangeRatesByDate(filter: $filter) {
    code
    message
    result{
      exchangeDate
      foreignExchangeRateInfoList{
        sourceCurrency
        exchangeCurrency
        sourceCurrency
        sourceCurrencyDisplay
        exchangeCurrencyDisplay
        bid
        ask
        changePct
        changePctMark
      }
    }
  }
}
`
