import { useMutation } from '@vue/apollo-composable'
import { Ref, reactive } from 'vue'
import * as currancyApi from '@/graphql/currency'
import { CashInfo } from './useCurrencyData'
import { Currency } from './useChangeType'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { formatNumberToFinance } from '@/utils/num_format'

export default function usePasswordDialog (cashInfo: CashInfo, foreignList: any, cashInItem: Ref<Currency>, cashOutItem: Ref<Currency>, accountActive: Ref<string>, btnDisabled: Ref<boolean>) {
  const router = useRouter()
  const passwordInfo = reactive({
    centerDialogVisible: false,
    password: '',
    errorTips: ''
  })

  const { loading: pwdLoading, mutate: checkPwdFcun, onDone: checkPwdDone } = useMutation(currancyApi.checkSessionPassword)
  const { loading: submitLoading, mutate: submit, onDone: submitDone } = useMutation(currancyApi.foreignExchangeApplicationCommit)

  const applyClick = () => {
    if (!btnDisabled.value) {
      return
    }
    passwordInfo.centerDialogVisible = true
  }

  const checkPwd = () => {
    if (pwdLoading.value || submitLoading.value) return
    const params = {
      param: {
        password: passwordInfo.password
      }
    }
    checkPwdFcun(params)
  }

  checkPwdDone((res) => {
    const { checkSessionPassword } = res.data
    if (checkSessionPassword.ok) {
      const params = {
        param: {
          tradeAccount: accountActive.value,
          fundAccount: accountActive.value,
          outCurrencyType: cashOutItem.value.value,
          inCurrencyType: cashInItem.value.value,
          exchangeOutMoney: cashInfo.outValue.replace(/,/g, ''),
          exchangeInMoney: cashInfo.inValue.replace(/,/g, ''),
          twofaAuthToken: checkSessionPassword.data.token
        }
      }
      submit(params)
    } else {
      passwordInfo.errorTips = checkSessionPassword?.reason.desc
    }
  })

  submitDone((res) => {
    const { foreignExchangeApplicationCommit } = res.data
    if (foreignExchangeApplicationCommit.code === 0) {
      passwordInfo.centerDialogVisible = false
      ElMessage.success('提交成功')
      router.push({ name: 'currency_record' })
    } else {
      passwordInfo.password = ''
      if (foreignExchangeApplicationCommit.code === 9) {
        cashInfo.btnTimeStatus = false
        cashInfo.codeNineStatus = true
        passwordInfo.centerDialogVisible = false
        cashInfo.inAllValue = formatNumberToFinance(foreignExchangeApplicationCommit.message)
        if (Number(foreignList.foreignExchangeData.isPartSwap)) {
          cashInfo.outErrorTips = `当前最多可兑入金额为${cashInfo.inAllValue},`
          cashInfo.outErrorAllTips = '全部兑入'
        } else {
          cashInfo.outErrorTips = `当前最多可兑入金额为${cashInfo.inAllValue}。`
          cashInfo.outErrorAllTips = ''
        }
      } else {
        ElMessage.error(foreignExchangeApplicationCommit.message)
      }
    }
  })
  return {
    passwordInfo,
    applyClick,
    checkPwd,
    pwdLoading,
    submitLoading
  }
}
