
import { defineComponent, reactive, Ref, ref, toRefs } from 'vue'
import useCurrencyRecord, { ParamsInpt } from './hooks/useCurrencyRecord'

export default defineComponent({
  setup() {
    const state = reactive({
      clientName: '',
      operatorAccount: '',
      applicationStatusApp: null,
      tradeAccount: null,
      exchangeOri: ''
    })
    const params: Ref<ParamsInpt> = ref({
      current: 1,
      size: 10,
      exchangeOri: '',
      applicationStatusApp: null,
      tradeAccount: null,
      deviceType: 3,
      operatorAccount: '',
      clientName: ''
    })
    const changeParams = () => {
      params.value.applicationStatusApp = state.applicationStatusApp
      params.value.tradeAccount = state.tradeAccount
      params.value.exchangeOri = state.exchangeOri
      params.value.operatorAccount = state.operatorAccount
      params.value.clientName = state.clientName
    }
    const {
      refetchRecord,
      statusList,
      orientationList,
      nameList,
      accountList,
      operList,
      tableData,
      total,
      loading
    } = useCurrencyRecord(params.value)
    const reset = () => {
      params.value.current = 1
      params.value.size = 10
      state.clientName = ''
      state.operatorAccount = ''
      state.applicationStatusApp = null
      state.tradeAccount = null
      state.exchangeOri = ''
      changeParams()
      refetchRecord({
        filterRecord: params.value,
        filterDict: {
          markList: [
            'FOREIGN_EXCHANGE_ORI',
            'AO_FOREGN_EXCHANGE_APP_STATUS',
            'SEC_MONEY_TYPE'
          ]
        }
      })
    }
    const handleSizeChange = (size: number) => {
      params.value.size = size
      changeParams()
    }
    const handleCurrentChange = (current: number) => {
      params.value.current = current
      changeParams()
    }
    const search = () => {
      params.value.applicationStatusApp = state.applicationStatusApp
      params.value.tradeAccount = state.tradeAccount
      params.value.exchangeOri = state.exchangeOri
      params.value.operatorAccount = state.operatorAccount
      params.value.clientName = state.clientName
      refetchRecord({
        filterRecord: params.value,
        filterDict: {
          markList: [
            'FOREIGN_EXCHANGE_ORI',
            'AO_FOREGN_EXCHANGE_APP_STATUS',
            'SEC_MONEY_TYPE'
          ]
        }
      })
    }
    const filterColor = (val: string) => {
      switch (val) {
        case '1':
          return '#363d56'
        case '2':
          return '#ff414e'
        case '3':
          return '#1cde8a'

        default:
          break
      }
    }
    return {
      ...toRefs(state),
      params,
      filterColor,
      tableData,
      total,
      statusList,
      orientationList,
      nameList,
      accountList,
      operList,
      search,
      reset,
      handleSizeChange,
      handleCurrentChange,
      loading
    }
  }
})
